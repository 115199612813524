import React, { useRef, useState } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import { api_url_ws } from "../../../config/api";

interface AudioStreamerProps {
    onStart: () => void;
    onStop: () => void;
    onData: (data: any) => void;
}

const AudioStreamer: React.FC<AudioStreamerProps> = ({ onStart, onStop, onData }) => {
    const [isRecording, setIsRecording] = useState(false);
    // Persistent references to mediaRecorder and socket
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const socketRef = useRef<WebSocket | null>(null);


    const handleStartRecording = async () => {
        setIsRecording(true);
        onStart();

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorderRef.current = mediaRecorder;

        const socket = new WebSocket(`${api_url_ws}/ws/voice_to_text/`);
        socketRef.current = socket;

        socket.onopen = () => {
            if (mediaRecorder) { // Ensure mediaRecorder is not null
                mediaRecorder.start(250); // Record in 500ms chunks
                mediaRecorder.ondataavailable = (event) => {
                    if (socket?.readyState === WebSocket.OPEN) {
                        if (event.data.size > 0) {
                            socket.send(event.data);
                        }
                    }
                };
            }
        };

        socket.onmessage = (event) => {
            const response = JSON.parse(event.data);
            if (response.transcript) {
                onData(response.transcript);
            }
            console.log(response);
        };
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        onStop();

        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(new TextEncoder().encode("EOS")); // Send end of stream signal
            socketRef.current.close();
        }
    };

    return (
        <div>
            {!isRecording ? (
                <IconButton onClick={handleStartRecording}>
                    <MicIcon />
                </IconButton>
            ) : (
                <IconButton onClick={handleStopRecording}>
                    <StopIcon />
                </IconButton>
            )}
        </div>
    );
};

export default AudioStreamer;