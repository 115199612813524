import React, { useState } from "react";
import {
  Typography,
  Divider,
  ListItem,
  IconButton,
  Box,
  Button,
  Link
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/StopCircleOutlined";
import { Message } from "./Message";
import { api_url } from "../../../config/api";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MessageBubble.css';

let audio: HTMLAudioElement | null = null;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const playMessage = async (
  sessionId: string,
  messageId: string,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await fetch(`${api_url}/get_audio/?session_id=${sessionId}&message_id=${messageId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    if (!response.body) {
      throw new Error("Response body is null");
    }

    // Create a MediaSource object for streaming
    const mediaSource = new MediaSource();
    audio = new Audio();
    audio.src = URL.createObjectURL(mediaSource);

    mediaSource.addEventListener('sourceopen', () => {
      const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
      const reader = response.body!.getReader();

      const readChunk = async () => {
        const { done, value } = await reader.read();
        if (done) {
          mediaSource.endOfStream();
          return;
        }

        // Wait for the SourceBuffer to be ready before appending the next chunk
        sourceBuffer.appendBuffer(value);
        sourceBuffer.addEventListener('updateend', readChunk, { once: true });
      };

      readChunk(); // Start reading and appending chunks
    });

    audio.play();
    setIsPlaying(true);

    audio.onended = () => {
      setIsPlaying(false);
      audio = null;
    };

  } catch (error) {
    console.error("Error playing message:", error);
    setIsPlaying(false);
  }
};

const stopMessage = (
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (audio) {
    audio.pause();
    audio = null;
  }
  setIsPlaying(false);
};

interface MessageBubbleProps {
  msg: Message;
  messageRetrievalHandler: (messageId: string) => void;
}

// Modified ListItemText to include conditional styling and play button
const MessageBubble = ({ msg, messageRetrievalHandler }: MessageBubbleProps) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const isUserMessage = msg.sender === "User"; // Adjust condition based on your data structure
  const aiColor = "#8a7da8";
  const userColor = "#d3e0ea";
  const bubbleColor = isUserMessage ? userColor : aiColor;
  const bubbleStyle = {
    backgroundColor: bubbleColor,
    padding: "20px",
    borderRadius: "20px",
    maxWidth: "60%",
    display: "inline-block",
    marginLeft: isUserMessage ? "auto" : "0",
    marginRight: isUserMessage ? "0" : "auto",
  };

  const togglePlayMessage = (message: Message) => {
    if (isPlaying) {
      stopMessage(setIsPlaying);
    } else {
      playMessage('<session_id>', message.id!, setIsPlaying);
    }
  };

  const formatText = (text: string) => {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
  }

  return (
    <ListItem
      style={{ justifyContent: isUserMessage ? "flex-end" : "flex-start" }}
    >
      <div style={bubbleStyle}>
        {msg.sender === "User" && (
          <Typography
            component="span"
            variant="body2"
            color={isUserMessage ? '#3b3a3a' : 'white'}
            dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
          >
          </Typography>
        )}
        {msg.sender === "AI" && (
          <>
            <img
              src="/EmiLogoNotTransparent.svg"
              alt="Emi Icon"
              style={{
                position: 'absolute',
                top: '-20px',
                left: '-20px',
                height: '60px',
                width: '60px'
              }}
            />
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
            >
            </Typography>
            <div className="play-button">
              <IconButton
                onClick={() => togglePlayMessage(msg)}
                size="small"
              >
                {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
              </IconButton>
            </div>
            {msg.longTextId && (
              <Box mt={1}>
                <Button size="small" onClick={() => messageRetrievalHandler(msg.longTextId!)}>
                  Show Summary
                </Button>
              </Box>
            )}
            {msg.tags && (
              <Box mt={1}>
                <Carousel
                  responsive={responsive}
                  infinite={false}
                  autoPlay={false}
                  arrows
                  // showDots={true}
                >
                  {msg.tags.map((tag, index) => {
                    const tagLink = tag.replace(/\s+/g, '-').toLowerCase();
                    const mockImage1 = '/mock-images/mock1.jpg';
                    const mockImage2 = '/mock-images/mock2.jpg';
                    const mockImage = index % 2 === 0 ? mockImage1 : mockImage2;
                    return (
                      <div>
                        <Box key={index} className="tag-tile">
                          <Link href={`https://www.gottman.com/blog/category/${tagLink}`} target='_blank' underline="none">
                            <img src={mockImage} alt={tag} className="tag-image" />
                            <Typography variant="body2" className="tag-title">
                              {tag}
                            </Typography>
                          </Link>
                        </Box></div>)
                  })}
                </Carousel>
              </Box>
            )}
          </>
        )}
      </div>
      <Divider />
    </ListItem>
  );
};

export default MessageBubble;
