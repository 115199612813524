import React from 'react';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';
// import './LoginPage.css';

const LoginPage: React.FC = () => {
    const { route } = useAuthenticator((context) => [context.route]);

    if (route === 'authenticated') {
        return <Navigate to="/chat" />;
    }

    const components = {
        Header() {
            return (
                <View textAlign="center">
                    <img
                        alt="Amplify logo"
                        src="/EmiLogo.svg"
                    />
                </View >
            );
        },
    };
    return (
        <Authenticator hideSignUp components={components}>
        </Authenticator>
    );
};

export default LoginPage;