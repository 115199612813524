import './Landing.css';

const Landing: React.FC = () => {
    return <>
        <div className="gradient"></div>
        <div className="content">
            <img src="EmiLogo.svg" alt="Gottman AI Logo" className="logo"></img>
            <a href="/login">
                <button className="sign-in-button">Get Started</button>
            </a>
        </div>
        <div className="footer-logo">
            <a href="https://gottman.com" target="_blank" rel="noopener noreferrer">
                <img src="GottmanLogo.svg" alt="Your Logo" />
            </a>
        </div>
    </>
}

export default Landing;