import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Landing from './components/pages/Landing/Landing';
import ChatPage from './components/pages/Chat/ChatPage';
import LoginPage from './components/pages/Login/login';
import ProtectedRoute from './components/ProtectedRoute';
import { Authenticator } from '@aws-amplify/ui-react';

function App() {

  return (
    <Authenticator.Provider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={
            <LoginPage />
            } />
          <Route path="/chat" 
            element={<ProtectedRoute><ChatPage /></ProtectedRoute>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
